import React, { Suspense, lazy } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { ChakraProvider } from "@chakra-ui/react";
import UserContext from "./context/user.context";
import { memo, useEffect, useState } from "react";
import { Loading, PreLoader, Sidemenu } from "./components";
import LoggedPages from "./Pages/LoggedPages/LoggedPages";
import SidebarContext from "./context/sidebar.context";
import requestToApi from "./components/axios";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

// after calling LogRocket.init()
const App = () => {
  const Apartment = lazy(() => import("./Pages/Apartment/Apartment"));
  const AddApartment = lazy(() => import("./Pages/AddApartment/AddApartment"));
  const Login = lazy(() => import("./Pages/Login/Login"));
  const Reservations = lazy(() => import("./Pages/Reservations/Reservations"));
  const PromoCodes = lazy(() => import("./Pages/PromoCodes/PromoCodes"));
  const Galery = lazy(() => import("./Pages/Galery/Galery"));

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({
    name: "",
    surrname: "",
    email: "",
    isLogged: false,
  });
  const location = useLocation();
  const [sideBarRefresher, setSideBarRefresher] = useState(false);
  useEffect(() => {
    LogRocket.init("gwppf4/administrator-beztroskiezycie");
    setupLogRocketReact(LogRocket);
    document.title = "Beztroskie życie | Admin Panel";
    window.scrollTo(0, 0);
    const getInforAboutAdmin = async () => {
      const infoFetch = await requestToApi
        .get("/admin/profile")
        .catch((err) => err.response);

      if (infoFetch.data.success) {
        setUser({ ...infoFetch.data, isLogged: true });
        setLoading(false);
        return;
      }

      setLoading(false);
    };
    getInforAboutAdmin();
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        loading,
      }}
    >
      <Loading>
        <ChakraProvider>
          <SidebarContext.Provider
            value={{ sideBarRefresher, setSideBarRefresher }}
          >
            <Sidemenu />
            <Suspense fallback={<PreLoader />}>
              <AnimatePresence
                mode="wait"
                onExitComplete={() => window.scrollTo(0, 0)}
              >
                <Routes location={location} key={location.pathname}>
                  <Route path="/login" element={<Login />} />
                  <Route path="/" element={<LoggedPages />}>
                    <Route path="" element={<Navigate to="/rezerwacje" />} />
                    <Route path="/rezerwacje" element={<Reservations />} />'
                    <Route path="/galeria" element={<Galery />} />'
                    <Route path="kody-promocyjne" element={<PromoCodes />} />
                    <Route path="apartament/:id" element={<Apartment />} />
                    <Route path="nowy-apartament" element={<AddApartment />} />
                  </Route>
                </Routes>
              </AnimatePresence>
            </Suspense>
          </SidebarContext.Provider>
        </ChakraProvider>
      </Loading>
    </UserContext.Provider>
  );
};

export default memo(App);
