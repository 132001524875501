import "./LoggedPages.scss";
import { Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import UserContext from "../../context/user.context";
import { useToast } from "@chakra-ui/react";

const LoggedPages = () => {
  // const toast = useToast({ duration: 3000, isClosable: true });
  const { user } = useContext(UserContext);
  const NotLoggedUser = () => {
    return <Navigate to="/login" />;
  };
  return user.isLogged ? <Outlet /> : NotLoggedUser();
};

export default LoggedPages;
