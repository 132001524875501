import axios from "axios";
import config from "../config.json";

const requestToApi = axios.create({
  baseURL: config.mode === "test" ? config.urls.test : config.urls.prod,
  withCredentials: true,
  // for deployment use: https://flaskapi.ip-pbx.eu/
});

export default requestToApi;
