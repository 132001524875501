import React, { useEffect, useState } from "react";
import "./ModalReservationDetails.scss";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Tooltip,
  useMediaQuery,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCalendarAlt,
  faCoins,
  faCreditCard,
  faEnvelope,
  faLink,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import urls from "../../../../urls";
import PreLoader from "../../PreLoader/PreLoader";
import requestToApi from "../../../axios";
import swal from "sweetalert";

const ModalReservationDetails = ({
  children,
  uuid_of_reservation,
  refreshFn,
  isNotInTable = false,
  className,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast({ duration: 3000, isClosable: true });
  const [reservationState, setReservationState] = useState({
    uuid: "",
    apartment: {},
    date_from: "",
    date_to: "",
    email: "",
    name: "",
    surname: "",
    phone: "",
    payment: "",
    price: "",
    isCanceled: false,
  });
  const [isSmallScreen] = useMediaQuery("(max-width: 800px)");
  // const {
  //   uuid,
  //   apartment,
  //   date_from,
  //   date_to,
  //   email,
  //   name,
  //   surname,
  //   phone,
  //   payment,
  //   price,
  //   isCanceled,
  // } = reservationState;
  const [loading, setLoading] = useState(false);

  const get_reservation = async () => {
    setLoading(true);
    const response = await requestToApi.get(
      urls.reservation_by_uuid(uuid_of_reservation)
    );

    if (response.data.success) {
      setReservationState({
        ...response.data.reservation.reservation,
        apartment: response.data.reservation.apartment,
      });
      setLoading(false);
      return;
    }
    toast({
      title: "Wystąpił błąd",
      description: response.data.error,
      status: "error",
    });
    setLoading(false);
  };
  useEffect(() => {
    if (isOpen) {
      get_reservation();
    }
  }, [isOpen]);
  const create_payment_link = async (reservation_uuid) => {
    setLoading(true);
    const payment_link = await requestToApi
      .post(urls.admin_create_payment_link, {
        reservation_uuid,
      })
      .catch((err) => err.response);

    if (payment_link.data.success) {
      toast({
        title: "Link do płatności został wygenerowany",
        description: "Link został wysłany na podany adres email",
        status: "success",
      });
      setReservationState((state) => ({
        ...state,
        payment: payment_link.data.payment_link,
      }));
      setLoading(false);
      return;
    }
    toast({
      title: "Wystąpił błąd",
      description: payment_link.data.error,
      status: "error",
    });
    setLoading(false);
  };

  const delete_reservation = async () => {
    const willDelete = await swal({
      title: "Czy na pewno chcesz usunąć rezerwację?",
      text: "Nie będzie możliwości cofnięcia tej operacji",
      icon: "warning",
      buttons: ["Anuluj", "Usuń"],
      dangerMode: true,
    });

    if (willDelete) {
      const response = await requestToApi.delete(urls.admin_reservations, {
        data: { reservation_number: reservationState.uuid },
      });

      if (response.data.success) {
        toast({
          title: "Rezerwacja została usunięta",
          status: "success",
        });
        await refreshFn();
        onClose();
        return;
      }
      toast({
        title: "Wystąpił błąd",
        description: response.data.error,
        status: "error",
      });
    }
  };

  return (
    <>
      {!isNotInTable ? (
        <Tooltip
          label={
            reservationState.isCanceled ? "Ta rezerwacja została anulowana" : ""
          }
          hasArrow
        >
          <tr
            className={`click-to-open-details ${
              reservationState.isCanceled ? "canceled" : ""
            }`}
            onClick={onOpen}
          >
            {children}
          </tr>
        </Tooltip>
      ) : (
        <div
          style={{ width: "fit-content", alignSelf: "flex-end" }}
          onClick={onOpen}
        >
          {children}
        </div>
      )}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={isSmallScreen ? "full" : "lg"}
      >
        <ModalOverlay />
        <ModalContent>
          <div className="modal-reservation-details-container">
            {loading && <PreLoader />}
            <ModalHeader>Informacje o rezerwującym</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {loading ? (
                <PreLoader />
              ) : (
                <>
                  <div>
                    <div className="user-name info-container">
                      <p className="mini-title">
                        <FontAwesomeIcon icon={faUser} /> Imie i nazwisko
                      </p>
                      <p className="info">
                        {reservationState.name} {reservationState.surname}
                      </p>
                    </div>
                    <div className="telephone info-container">
                      <p className="mini-title">
                        <FontAwesomeIcon icon={faPhone} /> Numer Telefonu
                      </p>
                      <p className="info">{reservationState.phone}</p>
                    </div>
                    <div className="email info-container">
                      <p className="mini-title">
                        <FontAwesomeIcon icon={faEnvelope} /> E-mail
                      </p>
                      <p className="info">{reservationState.email}</p>
                    </div>
                  </div>
                  <div className="reservation-info info-container">
                    <p className="mini-title">
                      <FontAwesomeIcon icon={faCalendarAlt} /> Daty Pobytu
                    </p>
                    <div className="dates">
                      <p className="info">
                        {moment(reservationState.date_from).format(
                          "DD/MM/YYYY H:mm"
                        )}{" "}
                        -{" "}
                        {moment(reservationState.date_to).format(
                          "DD/MM/YYYY H:mm"
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="info-container">
                    <p className="mini-title">
                      <FontAwesomeIcon icon={faBuilding} /> Apartament
                    </p>
                    <p className="info">{reservationState.apartment.name}</p>
                  </div>
                  <div className="info-container">
                    <p className="mini-title">
                      <FontAwesomeIcon icon={faCoins} /> Cena
                    </p>
                    <p className="info">{reservationState.price} €</p>
                  </div>
                  {reservationState.price > 0 && (
                    <div className="info-container">
                      <p className="mini-title">
                        {" "}
                        <FontAwesomeIcon icon={faCreditCard} />
                        Płatność
                      </p>
                      {reservationState.payment ? (
                        !reservationState.payment.payment_using_link ? (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={urls.stripe_check_price(
                              reservationState.payment.payment_id
                            )}
                            className="info-link"
                          >
                            Przejdz do Płatności!
                          </a>
                        ) : (
                          <Tooltip hasArrow label="Kliknij, aby skopiować">
                            <a
                              className="button-payment-link"
                              href={reservationState.payment.payment_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <FontAwesomeIcon icon={faLink} /> Link do
                              płatności
                            </a>
                          </Tooltip>
                        )
                      ) : (
                        <button
                          className="create-payment-link-button"
                          onClick={() =>
                            create_payment_link(reservationState.uuid)
                          }
                        >
                          <FontAwesomeIcon icon={faLink} /> Utwórz link do
                          płatności
                        </button>
                      )}
                    </div>
                  )}
                </>
              )}
            </ModalBody>
            <ModalFooter className="modal-footer">
              <button
                className="delete-reservation"
                onClick={() => delete_reservation()}
              >
                Usuń rezerwację
              </button>
              <button className="exit-button" onClick={onClose}>
                Wyjdź
              </button>
            </ModalFooter>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalReservationDetails;
