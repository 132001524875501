import "./Marker.scss";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Marker = ({ lat, lng, text }) => {
  return (
    <div className="pin">
      <FontAwesomeIcon className="pin-icon" icon={faLocationDot} />
      <p className="pin-text">{text}</p>
    </div>
  );
};

export default Marker;
