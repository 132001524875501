import "./Sidemenu.scss";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleRight,
  faBuilding,
  faCalendar,
  faPlus,
  faHome,
  faTicket,
  faPhotoFilm,
} from "@fortawesome/free-solid-svg-icons";
import { memo, useContext, useEffect, useState } from "react";
import UserContext from "../../../context/user.context";
import SidebarContext from "../../../context/sidebar.context";
import urls from "../../../urls";
import requestToApi from "../../axios";
import { useMediaQuery } from "@chakra-ui/react";
import MiniSideBar from "./MiniSideBar/MiniSideBar";
const Sidemenu = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { user, apartments } = useContext(UserContext);
  const { sideBarRefresher } = useContext(SidebarContext);
  const [apartmentList, setApartmentList] = useState(apartments);
  const [isSmallScreen] = useMediaQuery("(max-width: 800px)");
  const getApartments = async () => {
    const apartmentFetch = await requestToApi
      .get(urls.apartments)
      .catch((err) => err.resposne);

    if (apartmentFetch.data.success) {
      setApartmentList(apartmentFetch.data.apartments);
      return;
    }
  };
  useEffect(() => {
    const tak = async () => {
      await getApartments();
    };
    user.isLogged && tak();
  }, [user, sideBarRefresher]);
  return (
    user.isLogged &&
    (!isSmallScreen ? (
      <div className="sidemenu-container">
        <h1 className="title">Admin Panel</h1>
        <ul className="links">
          <NavLink className="link" to="/">
            <FontAwesomeIcon icon={faHome} /> Strona Główna
          </NavLink>
          <NavLink className="link" to="/rezerwacje">
            <FontAwesomeIcon icon={faCalendar} /> Rezerwacje
          </NavLink>
          <NavLink className="link" to="/kody-promocyjne">
            <FontAwesomeIcon icon={faTicket} /> Kody Promocyjne
          </NavLink>
          <NavLink className="link" to="/galeria">
            <FontAwesomeIcon icon={faPhotoFilm} /> Galeria
          </NavLink>
          <div className="menu-items">
            <button
              className="link"
              onClick={() => setMenuOpen((state) => !state)}
            >
              <FontAwesomeIcon icon={faBuilding} /> Apartamenty{" "}
              <FontAwesomeIcon icon={menuOpen ? faAngleDown : faAngleRight} />
            </button>
            {menuOpen && (
              <div className="menu-link-open">
                {apartmentList.map((apartment, index) => {
                  const { id, name } = apartment;
                  return (
                    <NavLink
                      key={index}
                      className="link"
                      to={`/apartament/${id}`}
                    >
                      {name}
                    </NavLink>
                  );
                })}
                <NavLink
                  to="/nowy-apartament"
                  className="add-new-apartment link"
                >
                  Dodaj Apartament <FontAwesomeIcon icon={faPlus} />
                </NavLink>
              </div>
            )}
          </div>
        </ul>
      </div>
    ) : (
      <MiniSideBar list_of_apartments={apartmentList} />
    ))
  );
};

export default memo(Sidemenu);
