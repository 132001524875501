import React, { useState } from "react";

const JoiInput = React.forwardRef((props, ref) => {
  const { schema, ...restProps } = props;
  const [error, setError] = useState("");
  const checkValidation = (e) => {
    if (restProps.onBlur) {
      restProps.onBlur(e);
    }
    const value = e.target.value;
    const check = schema.validate(value);
    if (check.error) {
      setError(check.error.message);
      return;
    }
    setError("");
  };
  return (
    <div>
      <input {...restProps} ref={ref} onBlur={checkValidation} />
      {error && (
        <p className="error-message" style={{ color: "red" }}>
          {error}
        </p>
      )}
    </div>
  );
});

export default JoiInput;
