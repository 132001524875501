import { useContext } from "react";
import UserContext from "../../context/user.context";
import PreLoader from "../UI/PreLoader/PreLoader";

const Loading = ({ children }) => {
  const { loading } = useContext(UserContext);
  return !loading ? children : <PreLoader />;
};

export default Loading;
