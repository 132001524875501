import "./PreLoader.scss";

const PreLoader = () => {
  return (
    <div className="backdrop-loader">
      <div className="loader-container">
        <div className="custom-loader"></div>
      </div>
    </div>
  );
};

export default PreLoader;
