import "./Header.scss";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStripeS } from "@fortawesome/free-brands-svg-icons";
import { memo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../../context/user.context";
import TransitionPage from "../../Transition/Transition";
import urls from "../../../urls";
import requestToApi from "../../axios";

const Header = ({ title = "Admin Panel" }) => {
  const { user, setUser } = useContext(UserContext);
  const { name, surname } = user;
  const navigateTo = useNavigate();
  const toast = useToast({ isClosable: true });
  const [isSmallScreen] = useMediaQuery("(max-width: 800px)");
  const animationConfig = {
    initial: { marginLeft: "50px", opacity: 0 },
    animate: { marginLeft: "0px", opacity: 1 },
    exit: { marginLeft: "50px", opacity: 0 },
  };
  const logout = async () => {
    const logOutFetch = await requestToApi
      .get(urls.admin_logout)
      .catch((err) => err.response);

    if (logOutFetch.data.success) {
      setUser({ name: "", surname: "", email: "", isLogged: false });
      toast({ title: "Wylogowano", status: "success" });
      navigateTo("/login");
      return;
    }
    toast({
      title: "Nie udało sie wylogować",
      description: logOutFetch.data.message,
      status: "error",
    });
  };
  return (
    <div className="header-container">
      <TransitionPage config={animationConfig}>
        <h1 className="title">{title}</h1>
      </TransitionPage>
      <div className="right-side">
        {!isSmallScreen && (
          <Tooltip hasArrow label="Przejdz do panelu płatności Stripe">
            <a
              href="https://stripe.com/dashboard"
              target="_blank"
              rel="noreferrer"
              className="payment-dashbord-link"
            >
              <FontAwesomeIcon icon={faStripeS} />
              Płatności
            </a>
          </Tooltip>
        )}
        <Menu>
          <MenuButton className="user-info">
            <FontAwesomeIcon icon={faCircleUser} />
            <p>
              {name} {surname}
            </p>
          </MenuButton>
          <MenuList>
            <MenuItem className="logout-button" onClick={() => logout()}>
              Wyloguj
            </MenuItem>
          </MenuList>
        </Menu>
      </div>
    </div>
  );
};

export default memo(Header);
