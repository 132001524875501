/* eslint-disable react-hooks/exhaustive-deps */
import "./ModalAddReservation.scss";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Switch,
  useMediaQuery,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import moment from "moment";
import JoiInput from "../../../JoiInput/JoiInput";
import Joi from "joi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import urls from "../../../../urls";
import PreLoader from "../../PreLoader/PreLoader";
import requestToApi from "../../../axios";

const ModalAddReservation = ({ children, refreshFn }) => {
  const [apartments, setApartments] = useState([]);
  const [selectedApartmentId, setSelectedApartmentId] = useState(1);
  const toast = useToast({ isClosable: true, position: "bottom-right" });
  const [loading, setLoading] = useState(false);
  const [dateError, setDateError] = useState("");
  const [paymentLink, setPaymentLink] = useState(false);
  const [isSmallScreen] = useMediaQuery("(max-width: 800px)");
  const { isOpen, onOpen, onClose } = useDisclosure();

  //states for inputs
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [nor, setNor] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [discount, setDiscount] = useState(0);

  // Joi validation

  const schemas = {
    name: Joi.string().min(3).max(30).required().messages({
      "string.empty": "Imię nie może być puste",
      "string.alphanum": "Imię nie może zawierać znaków specjalnych",
      "string.base": `Imię musi być tekstem`,
      "string.min": `Imię musi mieć minimum 3 znaki`,
      "string.max": `Imię musi mieć maksymalnie 30 znaków`,
    }),
    surname: Joi.string().min(3).max(30).required().messages({
      "string.empty": "Nazwisko nie może być puste",
      "string.alphanum": "Nazwisko nie może zawierać znaków specjalnych",
      "string.base": `Nazwisko musi być tekstem`,
      "string.min": `Nazwisko musi mieć minimum 3 znaki`,
      "string.max": `Nazwisko musi mieć maksymalnie 30 znaków`,
    }),
    nor: Joi.number().min(1).max(5).required().messages({
      "number.base": `Ilość osób musi być liczbą`,
      "number.min": `Minimalna ilość osób to 1`,
      "number.max": `Maksymalna ilość osób to 5`,
    }),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        "string.empty": "Email nie może być pusty",
        "string.email": "Email musi być prawidłowy",
        "string.base": `Email musi być tekstem`,
      }),
    phone: Joi.string().min(9).max(12).required().messages({
      "string.empty": "Numer telefonu nie może być pusty",
      "string.base": `Numer telefonu musi być tekstem`,
      "string.min": `Numer telefonu musi mieć minimum 9 znaków`,
      "string.max": `Numer telefonu musi mieć maksymalnie 12 znaków`,
    }),
    discount: Joi.number().min(0).max(100).messages({
      "number.base": `Rabat musi być liczbą`,
      "number.min": `Minimalny rabat to 0%`,
      "number.max": `Maksymalny rabat to 100%`,
    }),
  };
  const checkValidation = Joi.object(schemas);

  const clearInputs = () => {
    setDateFrom("");
    setDateTo("");
    setName("");
    setSurname("");
    setNor("");
    setEmail("");
    setPhone("");
    setDiscount(0);
  };
  useEffect(() => {
    const getApartments = async () => {
      setLoading(true);
      const response = await requestToApi
        .get(urls.admin_apartments)
        .catch((err) => err.resposne);

      if (response.data.success) {
        setApartments(response.data.apartments);
        setSelectedApartmentId(
          response.data.apartments[0] ? response.data.apartments[0].id : null
        );
        setLoading(false);
        return;
      }
      toast({
        title: "Nie udało sie pobrać informacji",
        description: `${response.data.message}. Spróbuj ponownie później`,
        status: "error",
      });
      onClose();
    };
    isOpen && getApartments();
  }, [isOpen]);

  const checkDatesAvilvable = async () => {
    if (dateFrom && dateTo) {
      if (
        moment(dateFrom).isAfter(dateTo) ||
        !moment(dateFrom).isSame(dateTo)
      ) {
        const ifDateAvilvable = await requestToApi
          .post(urls.check_reservation(selectedApartmentId.toString()), {
            date_from: moment(dateFrom).format("DD/MM/YYYY"),
            date_to: moment(dateTo).format("DD/MM/YYYY"),
          })
          .catch((err) => err.response);

        if (ifDateAvilvable.data.success) {
          setDateError("");
          return;
        }
        toast({
          title: "Nie udało się dodać rezerwacji",
          description: ifDateAvilvable.data.error,
          status: "error",
        });
        setDateError(ifDateAvilvable.data.message);
      }
    }
  };

  const addReservation = async (e) => {
    e.preventDefault();
    const data = {
      name: name,
      surname: surname,
      nor: nor,
      email: email,
      phone: phone,
      discount: discount,
    };
    const validation = checkValidation.validate(data, { abortEarly: false });
    if (validation.error) {
      toast({
        title: "Nie udało się dodać rezerwacji",
        description: `${validation.error.details[0].message}`,
        status: "error",
      });
      return;
    }
    if (dateError) {
      toast({
        title: "Nie udało się dodać rezerwacji",
        description: `${dateError}`,
        status: "error",
      });
      return;
    }
    setLoading(true);
    const request = await requestToApi
      .post(urls.admin_reservations, {
        ...data,
        id: selectedApartmentId,
        date_from: moment(dateFrom).format("DD/MM/YYYY"),
        date_to: moment(dateTo).format("DD/MM/YYYY"),
        wants_payment_link: paymentLink,
      })
      .catch((err) => err.response);
    if (request.data.success) {
      toast({
        title: "Dodano rezerwację",
        description: `${request.data.message}`,
        status: "success",
      });
      await refreshFn();
      onClose();
      clearInputs();
      setLoading(false);
      return;
    }
    toast({
      title: "Nie udało się dodać rezerwacji",
      description: request.data.error,
      status: "error",
    });
    setLoading(false);
  };
  useEffect(() => {
    checkDatesAvilvable();
  }, [selectedApartmentId]);
  return (
    <>
      <div onClick={onOpen} style={{ width: "fit-content" }}>
        {children}
      </div>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={isSmallScreen ? "full" : "3xl"}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <div className="modal-add-reservatiopn-container">
            {loading && <PreLoader />}
            <form onSubmit={addReservation}>
              <ModalHeader>Nowa rezerwacja</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <div className="form-add-apartment">
                  <div className="input-group">
                    <p>Wybierz apartament</p>
                    <select
                      onChange={(e) =>
                        setSelectedApartmentId(
                          e.target.options[e.target.selectedIndex].value
                        )
                      }
                    >
                      {apartments.map((apartment, index) => (
                        <option key={index} value={apartment.id}>
                          {apartment.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="input-group">
                    <p>Imie i Nazwisko</p>
                    <div className="split-inputs">
                      <div className="input">
                        <JoiInput
                          schema={schemas.name}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          type="text"
                        />
                        <span>Imię</span>
                      </div>
                      <div className="input">
                        <JoiInput
                          schema={schemas.surname}
                          value={surname}
                          onChange={(e) => setSurname(e.target.value)}
                          type="text"
                        />
                        <span>Nazwisko</span>
                      </div>
                    </div>
                  </div>
                  <div className="input-group">
                    <p>E-mail</p>
                    <JoiInput
                      placeholder="Wpisz email"
                      schema={schemas.email}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="text"
                    />
                  </div>
                  <div className="input-group">
                    <p>Numer Telefonu</p>
                    <JoiInput
                      placeholder="123456789"
                      schema={schemas.phone}
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      type="tel"
                    />
                  </div>
                  <div className="input-group">
                    <p>Ilość Osób</p>
                    <JoiInput
                      placeholder="Wpisz liczbe osób"
                      schema={schemas.nor}
                      value={nor}
                      onChange={(e) => {
                        if (isNaN(e.target.value)) return;
                        setNor(Number(e.target.value));
                      }}
                      type="text"
                    />
                  </div>
                  <div className="input-group">
                    <p>Daty</p>
                    {dateError && (
                      <p className="error-message" style={{ color: "red" }}>
                        {dateError}
                      </p>
                    )}
                    <div className="split-inputs">
                      <div className="input">
                        <input
                          type="date"
                          value={dateFrom}
                          onChange={(e) => setDateFrom(e.target.value)}
                          placeholder="dd-mm-yyyy"
                          onBlur={() => checkDatesAvilvable()}
                          min={moment().format("YYYY-MM-DD")}
                        />
                        <span>Data przyjazdu</span>
                      </div>
                      <div className="input">
                        <input
                          type="date"
                          value={dateTo}
                          onChange={(e) => setDateTo(e.target.value)}
                          placeholder="dd-mm-yyyy"
                          onBlur={() => checkDatesAvilvable()}
                          min={moment().format("YYYY-MM-DD")}
                        />
                        <span>Data wyjazdu</span>
                      </div>
                    </div>
                  </div>

                  <div className="split-div">
                    <div className="input-group">
                      <p>Promocja</p>
                      <div className="mumber-div">
                        <JoiInput
                          type="text"
                          schema={schemas.discount}
                          value={discount}
                          onChange={(e) => {
                            if (isNaN(e.target.value)) return;
                            setDiscount(Number(e.target.value));
                          }}
                          placeholder="Wpisz wartość promocji"
                        />
                        %
                      </div>
                    </div>
                    <div className="input-group">
                      <p>Czy utworzyc link do płatności?</p>
                      <Switch
                        size="lg"
                        disabled={
                          discount < 0 || discount === 100 || discount > 100
                        }
                        onChange={() => setPaymentLink((state) => !state)}
                      />
                    </div>
                  </div>
                </div>
              </ModalBody>

              <ModalFooter className="modal-footer">
                <button className="quit-modal" type="button" onClick={onClose}>
                  Anuluj
                </button>
                <button className="submit-button" disabled={loading}>
                  Wykonaj rezerwację <FontAwesomeIcon icon={faCheck} />
                </button>
              </ModalFooter>
            </form>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalAddReservation;
