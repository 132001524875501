import "./ModalAddFile.scss";

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { createRef, useContext, useState } from "react";
import ApartmentContext from "../../../../context/apartments.context";
import urls from "../../../../urls";
import requestToApi from "../../../axios";
import PreLoader from "../../PreLoader/PreLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faImage } from "@fortawesome/free-solid-svg-icons";
const ModalAddFile = ({
  children,
  ImageSetState,
  ImageState,
  fileFromDatabase = false,
  idOfImage = 0,
  changeFile = false,
  indexOfFile = null,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id } = useContext(ApartmentContext);
  const [file, setFile] = useState(null);
  const fileRef = createRef(null);
  const [loading, setLoading] = useState(false);
  const toast = useToast({ isClosable: true, position: "bottom-right" });
  const resetFile = () => {
    setFile(null);
    setLoading(false);
  };

  const send = async () => {
    if (id !== "new") {
      if (!changeFile) {
        return await requestToApi.post(
          urls.admin_apartments_images,
          {
            file: file,
            id: id,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }
      return await requestToApi.patch(
        urls.admin_apartments_images,
        {
          file: file,
          idOfApartment: id,
          idOfImage: idOfImage,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    }
    if (!changeFile) {
      ImageSetState((prev) => [...prev, { name: file, id: ImageState.length }]);
      toast({
        title: "Sukces",
        description: "Zdjęcie zostało dodane",
        status: "success",
      });
      onClose();
      return;
    }
    const imageFromFind = ImageState.find((image) => image.id === idOfImage);
    imageFromFind.name = file;
    ImageSetState((prev) => [...prev]);
    toast({
      title: "Sukces",
      description: "Zdjęcie zostało zmienione",
      status: "success",
    });
    onClose();
  };
  const addFile = async () => {
    if (file) {
      setLoading(true);
      const request = await send();
      if (!changeFile || id === "new") {
        setLoading(false);
        resetFile();
        if (request) {
          ImageSetState((prev) => [
            ...prev,
            { name: file, id: request.data.id, fromDatabase: false },
          ]);
          onClose();
        }
        return;
      }
      if (request.data.success) {
        if (!changeFile) {
          ImageSetState((prev) => [
            ...prev,
            { name: file, id: request.data.id, fromDatabase: false },
          ]);
          toast({
            title: "Sukces",
            description: "Zdjęcie zostało dodane",
            status: "success",
          });
          onClose();
          setLoading(false);
          resetFile();
          return;
        }
        const imageFromFind = ImageState.find(
          (image) => image.id === idOfImage
        );
        imageFromFind.name = file;
        imageFromFind.fromDatabase = false;
        ImageSetState((prev) => [...prev]);
        toast({
          title: "Sukces",
          description: "Zdjęcie zostało zmienione",
          status: "success",
        });
        setLoading(false);
        resetFile();
        onClose();
        return;
      }
      toast({
        title: "Błąd",
        description: "Wystąpił błąd podczas dodawania zdjęcia",
        status: "error",
      });
      setLoading(false);
      return;
    }
    toast({
      title: "Błąd",
      description: "Nie wybrano zdjęcia",
      status: "error",
    });
  };

  const removeImage = async () => {
    if (changeFile && id !== "new") {
      const request = await requestToApi
        .post(urls.delete_image, {
          id: idOfImage,
        })
        .catch((err) => err.response);
      if (request.data.success) {
        ImageSetState((prev) => [
          ...prev.filter((image) => image.id !== idOfImage),
        ]);
        toast({
          title: "Sukces",
          description: "Zdjęcie zostało usunięte",
          status: "success",
        });
        onClose();
        return;
      }
      toast({
        title: "Błąd",
        description: "Wystąpił błąd podczas usuwania zdjęcia",
        status: "error",
      });
      return;
    }
    ImageSetState((prev) => [
      ...prev.filter((image) => image.id !== idOfImage),
    ]);
    toast({
      title: "Sukces",
      description: "Zdjęcie zostało usunięte",
      status: "success",
    });
    onClose();
  };
  return (
    <>
      <div onClick={onOpen}>{children}</div>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          {loading && <PreLoader />}
          <ModalHeader>Nowe Zdjęcie</ModalHeader>
          <ModalCloseButton />
          <ModalBody className="add-file-modal-body">
            {isOpen && (
              <>
                {!ImageState[indexOfFile] && file && (
                  <img
                    alt="Zdjęcie"
                    src={URL.createObjectURL(file)}
                    style={{ width: "100%" }}
                  />
                )}
                {changeFile && (
                  <img
                    alt="Zdjęcie"
                    src={
                      changeFile
                        ? file
                          ? URL.createObjectURL(file)
                          : fileFromDatabase
                          ? urls.url_for_image(ImageState[indexOfFile].name)
                          : URL.createObjectURL(ImageState[indexOfFile].name)
                        : null
                    }
                  />
                )}
                <input
                  type="file"
                  ref={fileRef}
                  style={{ display: "none" }}
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                  }}
                />

                <button
                  className="add-file"
                  onClick={() => fileRef.current.click()}
                >
                  Wybierz Plik <FontAwesomeIcon icon={faImage} />
                </button>
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Zamknij
            </Button>
            {ImageState[indexOfFile] && (
              <Button
                onClick={() => removeImage()}
                variant="ghost"
                colorScheme="red"
              >
                Usuń Zdjęcie
              </Button>
            )}
            <Button onClick={() => addFile()} variant="ghost">
              {changeFile ? "Zmień Zdjęcie" : "Dodaj Zdjęcie"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalAddFile;
