import { motion } from "framer-motion";

const TransitionPage = ({
  children,
  duration = 0.3,
  config = null,
  stateToAnimate = null,
}) => {
  const animationConfiguration = config
    ? config
    : {
        initial: {
          right: "-40%",
          opacity: 0,
        },
        animate: {
          right: "0%",
          opacity: 1,
        },
        exit: { right: "40%", opacity: 0 },
      };
  return (
    <motion.div
      key={stateToAnimate}
      variants={animationConfiguration}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: duration }}
    >
      {children}
    </motion.div>
  );
};

export default TransitionPage;
