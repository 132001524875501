import config from "./config.json";

const urls = {
  apartment_using_id: (id) => `/apartament/${id}`,
  check_reservation: (id) => `/checkReservation-avilvable/${id}`,
  admin_apartment_using_id: (id) => `/admin/apartment/${id}`,
  admin_apartments_images: "/admin/apartaments/images",
  admin_apartments: "/admin/apartaments",
  admin_promocodes: "/admin/promocodes",
  admin_login: "/admin/login",
  admin_logout: "/admin/logout",
  admin_reservations: "/admin/reservations",
  admin_create_payment_link: "/admin/reservation/payment_link",
  apartments: "/apartments",
  reservation_by_uuid: (uuid) => `/reservation/${uuid}`,
  reservation_with_small_data: (uuid) => `/reservation_small_info/${uuid}`,
  images: "/images",
  add_image_to_category:  `/admin/add_image_to_category`,
  delete_image: "/admin/delete_image",  
  url_for_image: (name) =>
    `${
      config.mode === "test" ? config.urls.test : config.urls.prod
    }/images/${name}`, // for deployment use: https://flaskapi.ip-pbx.eu/images/
  stripe_check_price: (id) =>
    `https://dashboard.stripe.com/test/payments/${id}`,
};
export default urls;
