import "./MiniSideBar.scss";
import {
  faAngleDown,
  faAngleUp,
  faBars,
  faBuilding,
  faCalendar,
  faHouse,
  faPhotoVideo,
  faTicket,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { NavLink, useLocation } from "react-router-dom";
import { faStripeS } from "@fortawesome/free-brands-svg-icons";
const MiniSideBar = ({ list_of_apartments }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [apartmentMenuOpen, setApartmentMenuOpen] = useState(false);
  const location = useLocation();

  const animatonVariant = {
    open: {
      x: "0px",
    },
    closed: {
      x: "-150%",
    },
  };

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <>
      <button className="sidebar-button" onClick={() => setIsOpen(true)}>
        <FontAwesomeIcon icon={faBars} />
      </button>
      <motion.div
        variants={animatonVariant}
        initial="closed"
        animate={isOpen ? "open" : "closed"}
        className="sidebar"
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <h1 className="title-admin-panel">Admin Panel</h1>
        <ul className="sidebar-list">
          <NavLink className="sidebar-link" to="/">
            <FontAwesomeIcon icon={faHouse} />
            Strona Główna
          </NavLink>
          <NavLink className="sidebar-link" to="/rezerwacje">
            <FontAwesomeIcon icon={faCalendar} />
            Rezerwacje
          </NavLink>
          <NavLink className="sidebar-link" to="/kody-promocyjne">
            <FontAwesomeIcon icon={faTicket} />
            Kody Promocyjne
          </NavLink>
          <NavLink className="sidebar-link" to="/galeria">
            <FontAwesomeIcon icon={faPhotoVideo} />
            Galeria
          </NavLink>
          <button
            className="sidebar-link"
            onClick={() => setApartmentMenuOpen((state) => !state)}
          >
            <FontAwesomeIcon icon={faBuilding} />
            Apartamenty{" "}
            <FontAwesomeIcon
              icon={apartmentMenuOpen ? faAngleUp : faAngleDown}
            />
          </button>
          {apartmentMenuOpen && (
            <ul className="apartment-list">
              {list_of_apartments.map((apartment, index) => (
                <NavLink
                  key={index}
                  className="apartment-link"
                  to={`/apartament/${apartment.id}`}
                >
                  {apartment.name}
                </NavLink>
              ))}
              <NavLink
                className="apartment-link add-apartment"
                to="/nowy-apartament"
              >
                Dodaj Apartament
              </NavLink>
            </ul>
          )}
          <a
            href="https://stripe.com/dashboard"
            target="_blank"
            rel="noreferrer"
            className="payment-dashbord-link sidebar-link"
          >
            <FontAwesomeIcon icon={faStripeS} />
            Płatności
          </a>
        </ul>
        <button className="close-side-bar" onClick={() => setIsOpen(false)}>
          <FontAwesomeIcon icon={faXmark} />
        </button>
      </motion.div>
    </>
  );
};

export default MiniSideBar;
